<template>
    <form action="#" method="POST" class="px-5 py-2">
        <form-wizard @on-complete="saveData" title="Arrendatario" subtitle="Formulario para arrentadarios"  finishButtonText="Finalizar" :color='customColor'>
            <template slot="footer" slot-scope="props">
                <div class="wizard-footer-left">
                    <wizard-button  v-if="props.activeTabIndex > 0 " @click.native="props.prevTab()" :style="props.fillButtonStyle">Anterior</wizard-button>
                </div>
                <div class="wizard-footer-right">
                    <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle" :disabled="validateData(props.activeTabIndex)">Siguiente</wizard-button>
                    <wizard-button v-else class="wizard-footer-right" @click.native="saveData" :style="props.fillButtonStyle" :disabled="request_elements.length == 0">Finalizar </wizard-button>
                </div>
            </template>
          <tab-content title="Datos Personales">
            <!-- start first tab -->
              <div class="px-4 py-5 sm:p-6 divide-y">
                  <div class="mb-3">
                      <b><h2>Ingresa tus datos </h2></b>
                  </div>
                  <div class="grid grid-cols-6 gap-6 pt-3">
                      <div class="col-span-6 sm:col-span-3">
                          <label
                              for="rut"
                              class="block font-medium text-gray-700"
                              >RUT</label
                          >
                          <input
                              type="text"
                              name="rut"
                              id="rut"
                              @keyup="formatRut"
                              v-model="rut"
                              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-md border-gray-300 rounded-md h-7"
                              maxlength="10"
                          />
                          <p v-if="lessee.rut == ''" class="pt-2 px-1 text-red-500">Debes ingresar un rut válido</p>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                          <label
                              for="name"
                              class="block font-medium text-gray-700">Nombre</label>
                          <input
                              type="text"
                              name="name"
                              id="name"
                              v-model="lessee.name"
                              autocomplete="given-name"
                              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-md border-gray-300 rounded-md h-7"
                          />
                          <p v-if="lessee.name == ''" class="pt-2 px-1 text-red-500">Debes ingresar tu(s) nombre(s)</p>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                          <label
                              for="last_name"
                              class="block font-medium text-gray-700"
                              >Apellido(s)</label
                          >
                          <input
                              type="text"
                              name="last_name"
                              id="last_name"
                              v-model="lessee.last_name"
                              autocomplete="family-name"
                              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-md border-gray-300 rounded-md h-7"
                          />
                          <p v-if="lessee.last_name == ''" class="pt-2 px-1 text-red-500">Debes ingresar tu(s) apellido(s)</p>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                          <label
                              for="email"
                              class="block font-medium text-gray-700"
                              >Email</label
                          >
                          <input
                              type="text"
                              name="email"
                              id="email"
                              v-model="lessee.email"
                              autocomplete="email"
                              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-md border-gray-300 rounded-md h-7"
                          />
                          <p v-if="lessee.email == ''" class="pt-2 px-1 text-red-500">Debes ingresar tu email</p>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                          <label
                              for="phone"
                              class="block font-medium text-gray-700"
                              >Teléfono</label
                          >
                          <input
                              type="tel"
                              name="phone"
                              id="phone"
                              v-model="lessee.phone"
                              autocomplete="phone"
                              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-md border-gray-300 rounded-md h-7"
                          />
                          <p v-if="lessee.phone == ''" class="pt-2 px-1 text-red-500">Debes ingresar tu teléfono</p>
                      </div>
                  </div>
              </div>
            <!-- end first tab -->
          </tab-content>
          <tab-content title="Bienes">
            <!-- start second tab -->
            <div class="px-4 py-5 sm:p-6 divide-y">
                <div class="mb-3">
                    <h2><b>Selecciona tu bien</b></h2>
                </div>
                <div class="grid grid-cols-6 gap-6 pt-3">
                    <div v-if="!projectId" class="col-span-6 sm:col-span-3">
                        <label
                            for="project"
                            class="block  font-medium text-gray-700"
                            >Proyecto</label
                        >
                        <select
                            id="project"
                            name="project"
                            v-model="request.project_id"
                            @change="getAssets"
                            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        >
                            <option value="" disabled
                                >Selecciona un proyecto</option
                            >
                            <option
                                v-for="project in projects"
                                :key="project.ID_PROYECTO"
                                :value="project.ID_PROYECTO"
                                >{{ project.NOM_PROYECTO }}</option
                            >
                        </select>
                        <p v-if="request.project_id == ''" class="pt-2 px-1 text-red-500">Debes seleccionar un proyecto</p>
                    </div>
                    <div class="col-span-6 sm:col-span-3" v-if="request.project_id != ''">
                        <label
                            for="asset"
                            class="block font-medium text-gray-700"
                            >Bien</label
                        >
                        <select
                            id="asset"
                            name="asset"
                            v-model="request.asset_id"
                            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        >
                            <option value="" disabled
                                >Seleccione un bien</option
                            >
                            <option
                                v-for="asset in assets"
                                :key="asset.ID_BIEN"
                                :value="asset.ID_BIEN"
                                >{{
                                    `${asset.TIPO_BIEN} ${asset.NUM_BIEN}`
                                }}</option
                            >
                        </select>
                        <p v-if="request.asset_id == ''" class="pt-2 px-1 text-red-500">Debes seleccionar un bien</p>
                    </div>
                </div>
            </div>
            <!-- end second tab -->
          </tab-content>
          <tab-content title="Requerimientos">
            <!-- start thirty tab -->
              <request-element-form
                  :customer-name="customerName"
                  :places="places"
                  :has-project="isProjectSelected(request.project_id)"
                  :isInsideAftersaleWidget="isInsideAftersaleWidget"
                  @save-request="saveRequest"
              ></request-element-form>
            <!-- end thirty tab -->
          </tab-content>
        </form-wizard>

        <div class="shadow overflow-hidden sm:rounded-md">
            <div class="w-100">
                <table
                    v-show="request_elements.length"
                    class="border-collapse w-full"
                >
                    <thead>
                        <tr>
                            <th
                                class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 sm:hidden lg:table-cell"
                            >
                                Lugar
                            </th>
                            <th
                                class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 sm:hidden lg:table-cell"
                            >
                                Problema
                            </th>
                            <th
                                class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 sm:hidden lg:table-cell"
                            >
                                Item
                            </th>
                            <th
                                class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 sm:hidden lg:table-cell"
                            >
                                Detalle Item
                            </th>
                            <th
                                class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 sm:hidden lg:table-cell"
                            >
                                Descripcion
                            </th>
                            <th
                                class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 sm:hidden lg:table-cell"
                            >
                                Borrar
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(request_element, index) in request_elements"
                            :key="index"
                            class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
                        >
                            <td
                                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                            >
                                <span
                                    class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase"
                                    >Lugar</span
                                >
                                {{ request_element.place.name }}
                            </td>
                            <td
                                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                            >
                                <span
                                    class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase"
                                    >Problema</span
                                >
                                {{ request_element.problem.name }}
                            </td>
                            <td
                                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                            >
                                <span
                                    class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase"
                                    >Item</span
                                >
                                {{ request_element.item.name }}
                            </td>
                            <td
                                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                            >
                                <span
                                    class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase"
                                    >Detalle item</span
                                >
                                {{ request_element.item_detail.name }}
                            </td>
                            <td
                                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                            >
                                <span
                                    class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase"
                                    >Descripción</span
                                >
                                {{ request_element.description }}
                            </td>
                            <td
                                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                            >
                                <span
                                    class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase"
                                    >Borrar</span
                                >
                                <button
                                    @click.prevent="deleteTableItem(index)"
                                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    Eliminar
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import { afterSaleAPI } from "@/modules/api.js";
import RequestElementForm from "@/components/RequestElementForm";
export default {
    name: "LesseeForm",
    props: {
        customerName: String,
        projectId: {
            type: Number,
            required: false,
        },
        isInsideAftersaleWidget: { type: Boolean, default: false },
    },
    data() {
        return {
            rut: '',
            projects: [],
            assets: [],
            request_elements: [],
            places: [],
            lessee: {
                rut: "",
                name: "",
                last_name: "",
                email: "",
                phone: "",
            },
            request: {
                project_id: this.projectId || "",
                asset_id: "",
            },
        };
    },
    mounted() {
        this.getProjects();
        this.getPlaces();
        if (this.projectId) this.getAssets();
    },
    computed: {
        customColor(){
            if (this.isInsideAftersaleWidget){
                return '#00718C'
            }
            else
            {
                return this.customerName == 'security' ? '#008bcc' : '#4f46e5' 
            }
        }
    },
    methods: {
        async saveData() {
            const reqElementsMapped = this.request_elements.map(
                (request_element) => ({
                    description: request_element.description,
                    place_id: request_element.place.id,
                    item_id: request_element.item.id,
                    item_detail_id: request_element.item_detail.id,
                    problem_id: request_element.problem.id,
                    state: "active",
                    element_state_id: 3,
                })
            );
            try {
                await afterSaleAPI.post(`/lessees`, {
                    lessee: this.lessee,
                    project_id: this.request.project_id,
                    request: {
                        ...this.request,
                        request_elements_attributes: reqElementsMapped,
                    },
                    customer_name: this.customerName,
                });
                alert("Se guardó de manera exitosa");
            } catch (error) {
                console.log(error);
                alert("Ocurrió un error");
            }
        },
        async getProjects() {
            try {
                const response = await afterSaleAPI.get(
                    `/projects/customer_project/${this.customerName}`
                );
                this.projects = response.data.projects;
            } catch (error) {
                console.log(error);
            }
        },
        async getAssets() {
            this.request.asset_id = ""
            if (this.request.project_id) {
                try {
                    const response = await afterSaleAPI.get(
                        `/assets/by_project/${this.request.project_id}/${this.customerName}`
                    );
                    this.assets = response.data.assets;
                } catch (error) {
                    console.log(error);
                }
            }
        },
        async getPlaces() {
            try {
                const response = await afterSaleAPI.get(
                    `/places/by_customer/${this.customerName}`
                );
                this.places = response.data.places;
            } catch (error) {
                console.log(error);
            }
        },
        isProjectSelected(project) {
            return project ? true : false
        },
        async searchRut() {
            try {
                const response = await afterSaleAPI.get(
                    `/lessees/${this.lessee.rut}`
                );
                console.log(response.data);
                if (response.data.lessee) {
                    let { lessee } = response.data;
                    this.lessee = { ...lessee };
                } else {
                    this.lessee = {
                        name: "",
                        last_name: "",
                        email: "",
                        phone: "",
                        rut: this.lessee.rut,
                    };
                }
            } catch (error) {
                console.log(error);
            }
        },
        validateData(index){
            if (index == 0)
            {
                if(this.lessee.rut == "" || this.lessee.name == "" || this.lessee.last_name == "" || this.lessee.email == "" || this.lessee.phone == "")
                    return true
                else
                    return false
            }
            if (index == 1)
            {
                if(this.request.project_id == "" || this.request.asset_id == "")
                    return true
                else
                    return false
            }
            return false
        },
        saveRequest(request) {
            this.request_elements.push(request);
        },
        deleteTableItem(index) {
            this.request_elements.splice(index, 1);
        },
        digito(rut, dv) {
            let suma = 0
            let multiplicador = 2
            while (rut !== 0) {
                if (multiplicador === 8) {
                    multiplicador = 2
                }
                suma += (rut % 10) * multiplicador
                rut = Math.trunc(rut / 10)
                multiplicador++
            }
            suma = 11 - (suma % 11)
            switch (suma) {
                case 11:
                    return ("0" === dv)
                case 10:
                    return ("K" === dv.toUpperCase())
                default:
                    return (suma.toString() === dv)
            }
        },
        formatRut(){
            if(this.rut.length > 0){
                this.rut = this.rut.replace(/[^\dKk]/g, '')
                let dato = this.rut
                let largo = dato.length
                if (largo === 1) {
                    this.dv = dato
                    this.rut = ''
                    this.digito(this.rut, this.dv)
                    this.formato()
                } else if (largo > 1) {
                    this.dv = dato.substring(largo - 1)
                    this.rut = dato.substring(largo - 1, 0)
                    this.digito(this.rut, this.dv)
                    this.formato()
                }
            }
        },
        formato() {
            let rut = (this.rut) ? (parseInt(this.rut)) + '-' : ''
            this.rut = rut + this.dv
            this.lessee.rut = this.rut
        },
    },
    components: {
        RequestElementForm, FormWizard, TabContent
    },
};
</script>

