import Vue from 'vue'
import App from './App.vue'
import VueFormWizard from 'vue-form-wizard'
import vueCustomElement from "vue-custom-element";
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import "sweetalert2/dist/sweetalert2.min.css";

Vue.config.productionTip = false

Vue.use(VueFormWizard)
Vue.use(vueCustomElement);
Vue.customElement("mobysuite-aftersale-leaser", App);
