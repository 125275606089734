<template>
    <lessee-form :customer-name="customerName" :project-id="projectId" :isInsideAftersaleWidget="isInsideAftersaleWidget"/>
</template>

<script>
import LesseeForm from "./components/LesseeForm";

export default {
    name: "App",
    components: {
        LesseeForm,
    },
    props: {
        customerName: { type: String, default: null },
        projectId: { type: Number, default: null },
        isInsideAftersaleWidget: { type: Boolean, default: false },
    },
};
</script>
<style lang="scss">
mobysuite-aftersale-leaser{
    @import "assets/sass/tailwindcss/base.scss";
    @import "assets/sass/tailwindcss/components.scss";
    @import "assets/sass/tailwindcss/utilities.scss";
}
</style>