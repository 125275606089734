<template>
    <div class="px-4 py-5 sm:p-6 divide-y">
        <div class="mb-3">
            <h2 class="text-lg"><b>Requerimiento</b></h2>
        </div>
        <div class="grid grid-cols-6 gap-6 pt-3">
            <div class="col-span-6 sm:col-span-3">
                <label
                    for="project"
                    class="block font-medium text-gray-700"
                    >Lugar</label
                >
                <select
                    :disabled="!this.hasProject"
                    v-model="place"
                    @change="getItems"
                    class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                    <option value="" disabled>Selecciona un lugar</option>
                    <option
                        v-for="place in places"
                        :key="place.id"
                        :value="place"
                        >{{ place.name }}</option
                    >
                </select>
            </div>
            <div class="col-span-6 sm:col-span-3">
                <label
                    for="project"
                    class="block font-medium text-gray-700"
                    >Item</label
                >
                <select
                    :disabled="place.length"
                    v-model="item"
                    @change="getItemDetails"
                    class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                    <option value="" disabled>Selecciona un item</option>
                    <option
                        v-for="item in items"
                        :key="item.id"
                        :value="item"
                        >{{ item.name }}</option
                    >
                </select>
                <p v-if="!place" class="pt-2 px-1 text-red-500">Seleccione primero un lugar</p>
            </div>
            <div class="col-span-6 sm:col-span-3">
                <label
                    for="project"
                    class="block font-medium text-gray-700"
                    >Detalle de item</label
                >
                <select
                    :disabled="item.length"
                    v-model="item_detail"
                    @change="getProblems"
                    class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                    <option value="" disabled
                        >Selecciona un detalle de item</option
                    >
                    <option
                        v-for="item_detail in item_details"
                        :key="item_detail.id"
                        :value="item_detail"
                        >{{ item_detail.name }}</option
                    >
                </select>
                <p v-if="!item" class="pt-2 px-1 text-red-500">Seleccione primero un item</p>
            </div>
            <div class="col-span-6 sm:col-span-3">
                <label
                    for="project"
                    class="block font-medium text-gray-700"
                    >Problema</label
                >
                <select
                    :disabled="item_detail.length"
                    v-model="problem"
                    class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                    <option value="" disabled>Selecciona un problema</option>
                    <option
                        v-for="problem in problems"
                        :key="problem.id"
                        :value="problem"
                        >{{ problem.name }}</option
                    >
                </select>
                <p v-if="!item_detail" class="pt-2 px-1 text-red-500">Seleccione primero un detalle de item</p>
            </div>
            <div class="col-span-6 sm:col-span-6">
                <label
                    for="phone"
                    class="block font-medium text-gray-700"
                    >Descripción</label
                >
                <textarea
                    rows="4"
                    v-model="description"
                    class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 bloc w-full shadow-md border-gray-300 rounded-md"
                />
            </div>
        </div>
        <div class="py-5 px-2 text-red-500">
            <li v-if="!this.hasProject">No hay proyecto seleccionado</li>
             <li v-if="!problem">Faltan campos en las observaciones</li>
        </div>
        <div class="flex justify-end pt-3">
            <button
                @click.prevent="addRequestElement"
                :disabled="problem.length"
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-md font-medium rounded-md text-white"
                :style="customColor"
            >
                Agregar requerimiento
            </button>
        </div>
    </div>
</template>

<script>
import { afterSaleAPI } from "@/modules/api.js";
export default {
    props: {
        index: Number,
        places: Array,
        customerName: String,
        hasProject: Boolean,
        isInsideAftersaleWidget: { type: Boolean, default: false },
    },
    data() {
        return {
            items: Array,
            item_details: [],
            problems: [],
            description: "",
            place: "",
            item: "",
            item_detail: "",
            problem: "",
        };
    },
    computed: {
        customColor(){
            if (this.isInsideAftersaleWidget){
                return 'background-color: #00718C'
            }
            else
            {
                return this.customerName == 'security' ? 'background-color: #008bcc' : 'background-color: #4f46e5' 
            }
        }
    },
    methods: {
        async getItems() {
            this.item = ""
            this.item_detail = ""
            this.problem = ""
            try {
                const response = await afterSaleAPI.get(
                    `/items/by_customer/${this.customerName}/${this.place.id}`
                );
                this.items = response.data.items;
            } catch (error) {
                console.log(error);
            }
        },
        async getItemDetails() {
            this.item_detail = ""
            this.problem = ""
            try {
                const response = await afterSaleAPI.get(
                    `/item_details/by_customer/${this.customerName}/${this.item.id}`
                );
                this.item_details = response.data.item_details;
            } catch (error) {
                console.log(error);
            }
        },
        async getProblems() {
            this.problem = ""
            try {
                const response = await afterSaleAPI.get(
                    `/problems/by_customer/${this.customerName}/${this.item_detail.id}`
                );
                this.problems = response.data.problems;
            } catch (error) {
                console.log(error);
            }
        },
        addRequestElement() {
            this.$emit("save-request", {
                description: this.description,
                place: this.place,
                item: this.item,
                item_detail: this.item_detail,
                problem: this.problem,
            });
            this.description = "";
            this.place = "";
            this.item = "";
            this.item_detail = "";
            this.problem = "";
        },
    },
};
</script>

<style></style>
